
body{
  background-color: aqua;
}
.bottom-div-with-hidden-scrollbar::-webkit-scrollbar {
  width:5px;
  height: 8px;

}

.bottom-div-with-hidden-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(177, 176, 176); 
  border-radius: 10px;
  
}

/* Handle */
.bottom-div-with-hidden-scrollbar::-webkit-scrollbar-thumb {
  background: #C68A02; 
  border-radius: 10px;
  width: 5px;
}

/* Handle on hover */
.bottom-div-with-hidden-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #ac7902; 
}

.bottom-div-with-hidden-scrollbar {
  scrollbar-width: 5px; /* Firefox */
}

.btn {
  /* Define common button styles */
  color: #9a9996;
  font-weight: 300;
  font-family:'Montserrat'

}
.btn.active {
  /* Define styles for active button */
  font-weight: 600;
  color: #C68A02 !important;
  border-bottom: 2px solid #C68A02;
  border-top:none;
  border-left:none;
  border-right:none;
  border-Radius:0px;
  font-family:'Montserrat';
  font-size: 16px;
 

 

}

.btn.nonactive{
  color: #9a9996;
  font-weight: 300;
  border:none;
  font-family:'Montserrat';
  font-size: 16px;
}

@media (max-width:630px){
  .btn.active{
    font-size: 12px;
    font-weight: 600;
    color: #C68A02 !important;
    border-bottom: 2px solid #C68A02;
    border-top:none;
    border-left:none;
    border-right:none;
    border-Radius:0px;
    font-family:'Montserrat';
   
  }
  .btn.nonactive{
    color: #9a9996;
    font-weight: 300;
    border:none;
    font-family:'Montserrat';
    font-size: 12px;
  }
}


/* --------input field number diable scroll button */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}



/* changing default color of select tag in mobile view */
.select-tag-bg {
  background-color: #fff !important;
}

.date-tag-bg {
  background-color: #fff !important;
}

