.body-pdf {
    font-family: Arial, sans-serif;
    margin: 10px;
    padding: 0 10px 0 10px;
  
   
}

.container-pdf {
    max-width: auto;
    margin-left: 10px;
    margin-right: 10px;
    
   
  
}

.h1-pdf {
    text-align: center;
    padding-top: 50px;
    font-weight: bold;
    font-size: x-large;
}

.h2-pdf {
    text-align: center;
    padding-top: 15px;
    font-weight: bold;
    font-size: large;
}

.h3-pdf {
    text-align: center;
    padding-top: 15px;
    font-weight: bold;
    font-size: 14px;
}

.p-pdf {
    text-align: center;
    padding-top: 5px;
}

.table-pdf {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 20px;

}

.th-pdf{
    border: 1px dashed #ddd;
    padding: 8px;
    text-align: center;
    font-size: 13px;
}

.td-pdf {
    border: 1px dashed #ddd;
    padding: 8px;
    text-align: center;
}

.th-pdf {
    background-color: #f2f2f2;
   
}


.tr1 {
    text-align: center;
}

.location {
    margin-bottom: 25px;
}

.summary {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: large;
  
  
}

.summary table {
    width: auto;
    margin: 0 auto ;
    margin-top: 15px;

}

.bold {
    font-weight: bold;
}




/* ----------- */
.select-pdf {
    padding: 5px;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .label-pdf {
    margin-right: 10px;
  }
  
  .p-pdf {
    margin-top: 10px;
    font-size: 16px;
    
  }