/* .login-container{

  margin-top: 10%;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-container {
    text-align: center;
  }
  
  .spinner {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .form_main {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);
      padding: 30px 30px 30px 30px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
      position: relative;
      overflow: hidden;
      height: 350px;
    }
  
  
  
    .heading {
      font-size: 2em;
      color: #2e2e2e;
      font-weight: 700;
      margin: 5px 0 10px 0;
      z-index: 2;
    }
  
    .inputContainer {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
  
    .inputIcon {
      position: absolute;
      left: 3px;
    }
  
    .inputField {
      width: 100%;
      height: 30px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid rgb(173, 173, 173);
      margin: 10px 0;
      color: black;
      font-size: .8em;
      font-weight: 500;
      box-sizing: border-box;
      padding-left: 30px;
    }
  
    .inputField:focus {
      outline: none;
      border-bottom: 2px solid rgb(51, 50, 50);
    }
  
    .inputField::placeholder {
      color: rgb(80, 80, 80);
      font-size: 1em;
      font-weight: 500;
    }
  
    #button {
      z-index: 2;
      position: relative;
      width: 100%;
      border: none;
      background-color: rgb(7, 22, 245);
      height: 30px;
      color: white;
      font-size: .8em;
      font-weight: 500;
      letter-spacing: 1px;
      margin-top: 10px;
      cursor: pointer;
    }
  
    #button:hover {
      background-color: rgb(126, 84, 255);
    }
  
    .forgotLink {
      z-index: 2;
      font-size: .7em;
      font-weight: 500;
      color: rgb(44, 24, 128);
      text-decoration: none;
      padding: 8px 15px;
      border-radius: 20px;
    }
  
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
  }
  
  .loading-container {
      text-align: center;
  }
  
  .spinner {
      border: 6px solid rgba(0, 0, 0, 0.1);
      border-top: 6px solid #333;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
  }
  
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  
  
  .content-col{
      background-color: #f8fafc;
  
  
  }
  .Call-details{
      margin: 2% 0 1% 6% ;
  
  }
  
  /* ----------------------------------------------- */
  /*.container-details {
      display: flex;
      border: 1px ;
      margin-top: 0;
  
   }
  
  .column {
      width: 7.9%;
      margin: 1% 1% 1% 3%;
      height: 6vh;
      border-radius: 5px;
  
  
   }
   .column-searchbar {
      width: 22.333%;
      background-color: #ffff;
      margin: 1%;
      height: 4vh;
      border-radius: 5px;
   }
   .column-filter{
      width: 6.1%;
      margin: 1%;
      height: 6vh;
      border-radius: 5px;
   }
  
   /* ---------------search bar----------------------------------- */
  
   /*.searchbar {
      font-size: 14px;
      font-family: arial, sans-serif;
      color: #202124;
      display: flex;
      z-index: 3;
      background: white;
      border: 1px  #dfe1e5;
      box-shadow: none;
      border-radius: 5px;
      width: 100%;
      font-weight: lighter;
  
    }
  
  
  
    .searchbar-wrapper {
      flex: 1;
      display: flex;
      padding: 5px 8px 0 14px;
    }
  
    .searchbar-left {
      font-size: 14px;
      font-family: arial, sans-serif;
      color: #202124;
      display: flex;
      align-items: center;
      padding-right: 13px;
      margin-top: -5px;
    }
  
    .search-icon-wrapper {
      margin: auto;
    }
  
    .search-icon {
      margin-top: 3px;
      color: #9aa0a6;
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  
    .searchbar-icon {
      display: inline-block;
      fill: currentColor;
      height: 30px;
      line-height: 24px;
      position: relative;
      width: 24px;
    }
  
    .searchbar-center {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
    }
  
    .searchbar-input-spacer {
      color: transparent;
      flex: 100%;
      white-space: pre;
      height: 34px;
      font-size: 16px;
    }
  
    .searchbar-input {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, .87);
      word-wrap: break-word;
      outline: none;
      display: flex;
      flex: 100%;
      margin-top: -37px;
      height: 34px;
      max-width: 100%;
      width: 100%
    }
  
    .searchbar-right {
      display: flex;
      flex: 0 0 auto;
      margin-top: -5px;
      align-items: stretch;
      flex-direction: row
    }
  
    .searchbar-clear-icon {
      margin-right: 12px
    }
  
    /* ----------------table--------------------------------------------- */
   /*.table-container{
      background-color: #ffff;
      width: 92%;
      height: auto;
      margin: 1% 6%;
      border-radius: 5.3px;
      padding:2% ;
  
  
   }
   .thead {
      background-color: #202124;
   }
  
   /* --------------------------------------------------------- */
  /*.export-btn{
    margin: 0 0 0 89%;
  }
  
  
  
  @media (max-width: 768px) {
  
    .column { width: 100%; }
   
    .export-btn{ margin: 0 0 0 78%; }
  
    .add-new-btn{
      margin: 1% 0 0 57%;
    }
  
    }
    .filter-parent{
      flex-direction: row; width: 89%; justify-content: center; 
    }
  
    .filter-btn{
      margin: 1.5% 0 0 2%;
    }
  
    .add-new-btn{
      margin: 1.5% 0 0 70%;
    }
  
    .export-btn-filter{
      margin: 1.5% 0 0 2%;
    }
    .content-col-dash{
      overflow-y: scroll;
      overflow-x:hidden;
      background-color: #f8fafc;
  
     
  }
  .table-container-dashboard{
      background-color: #ffff;
      width: 93%;
      height: auto;
      margin: 1% 3%;
      border-radius: 5.3px;
      padding: 2% ;
      
     
   }
   .Call-details-dash{
      margin: 2% 0 1% 3% ;
      
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    color: #000000;
 }

.navbar-checked {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
 }

.navbar-checked:hover {
    border-color: #ccc;
 }

.navbar-checkmark {
    display: none;
 }

.navbar-checkmark-checked {
    display: block;
 }

 @media (max-width: 768px) {
   .navbar {
      flex-direction: column;
    }

   .navbar-checked {
      margin-top: 10px;
    }
 }
 
/* ---------------------------------- */
/*.switch {
    --button-width: 2.5em;
    --button-height: 1.3em;
    --toggle-diameter: 1em;
    --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
    --toggle-shadow-offset: 10px;
    --toggle-wider: 3em;
    --color-grey: #cccccc;
    --color-green: #5bee06;
   }
   
   .slider {
    display: inline-block;
    width: var(--button-width);
    height: var(--button-height);
    background-color: var(--color-grey);
    border-radius: calc(var(--button-height) / 2);
    position: relative;
    transition: 0.3s all ease-in-out;
   }
   
   .slider::after {
    content: "";
    display: inline-block;
    width: var(--toggle-diameter);
    height: var(--toggle-diameter);
    background-color: #fff;
    border-radius: calc(var(--toggle-diameter) / 2);
    position: absolute;
    top: var(--button-toggle-offset);
    transform: translateX(var(--button-toggle-offset));
    box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease-in-out;
   }
   
   .switch input[type="checkbox"]:checked + .slider {
    background-color: var(--color-green);
   }
   
   .switch input[type="checkbox"]:checked + .slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
    box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
   }
   
   .switch input[type="checkbox"] {
    display: none;
   }
   
   .switch input[type="checkbox"]:active + .slider::after {
    width: var(--toggle-wider);
   }
   
   .switch input[type="checkbox"]:checked:active + .slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
   }
   
   .button-container {
    display: flex;
    justify-content: flex-end;
    margin-right: auto;
 }

.button {
    background-color: #4923f1;
    border: none;
    color: white;
    padding: 7px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 0 0 0;
    cursor: pointer;
    border-radius: 5px;
 }

.button:hover {
    background-color: #3100e4;
 }
 .button-cancel{
    background-color: #fdfcff;
    border: none;
    color: rgb(3, 3, 3);
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 20px 5px 0 0;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #dee2e6 ;

 }

/*.time-picker{
    -webkit-datetime-edit-ampm-field {
        display: none;
    }
}

.sidebar {
   
  top: 0;
  left: 0;
  width: 60px;
  height: 100vh !important;
  background-color: #160042;
  float: left;
}

.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;
  
}

.sidebar-item:hover {
  background-color: #2c55fb;
  border-radius: 5px;
  margin: 5px;

}

.sidebar-symbol {
  color: #fff;
  font-size: 24px;
}
.company-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;
  margin-bottom: 100%;

}
     */
     @import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@100..900&family=Jaro:opsz@6..72&family=Lobster&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poetsen+One&display=swap');
     body {
       margin: 0;
       font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
     }
     
     code {
       font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
         monospace;
     }
     @tailwind base;
     @tailwind components;
     @tailwind utilities;   
     
     
  